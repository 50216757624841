import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const DataPreviewComponent = lazy(() => import('../components/DataPreview'))
const ProductPreviewComponent = lazy(
    () => import('../../ProductPreview/components/ProductPreview')
)

const DataPreviewRoute: RouteObject = {
    path: 'data-preview',
    element: <DataPreviewComponent />,
    children: [
        {
            index: true,
            element: <ProductPreviewComponent />,
        },
    ],
}

export default DataPreviewRoute
